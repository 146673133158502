import React from "react"

import Layout from "components/Layout/de"
import Gallery from "components/Gallery/Gallery"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Link } from "gatsby"

export default function(props) {
  const header = {
    text: "MoonVision Karriere",
    description:
      "Schließen Sie sich unserer Mission an, eine bessere Zukunft zu entwickeln, in der die KI eingesetzt wird, um die Welt um uns herum besser zu verstehen.",
  }
  return (
    <Layout header={header} location={props.location}>
      <div className="grey-background">
        <div className="vivid_teaser p-5">
          <Container>
            <p>Karriere</p>
            <h1>Arbeite @ MoonVision</h1>
            <p>
              Schließen Sie sich unserer Mission an, eine bessere Zukunft zu
              entwickeln, in der die KI eingesetzt wird, um die Welt um uns
              herum besser zu verstehen.
            </p>
          </Container>
        </div>
        <div className="container py-4">
          <h2>Offene Positionen</h2>
        </div>
        <div className="white-background">
          <Container>
            <Row className="py-5">
            <Col className="job my-2" md="12" lg="4">
                <div className="card h-100">
                  <div className="card-body">
                        <h2 className="card-title">Frontend Developer</h2>
                            <p className="card-text">
                            Hier brauchen wir deine Expertise und Kreativität, um die Frontend-Seite unserer app.moonvision.io Plattform zu verbessern. Du wirst eng mit unserem Backend-Team sowie unseren CV Engineers zusammenarbeiten, um neue Features zu entwickeln und das Design der Plattform zu verbessern. Daher ist eine starke Affinität zu UI/UX ebenfalls erwünscht.
                            </p>
                            <br />
                            <Link to="de/career/frontend-dev-role">
                              <Button className="btn btn-primary float-right">
                              Mehr erfahren
                              </Button>
                            </Link>
                          </div>
                      </div>
             </Col>
            </Row>
          </Container>
        </div>
        <div className="container py-4">
          <h2>Office Life @ MoonVision</h2>
        </div>
        <div className="pb-4">
          <Gallery />
        </div>
      </div>
    </Layout>
  )
}
